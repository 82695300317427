import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import BigImage from "./BigImage";

const Container = styled.div`
  max-width: 1768px;
  margin: 0 auto;
  padding: 0 40px;
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 5;
  h1 {
    font-weight: 400;
    font-family: "Arial", Arial, sans-serif;
    transform: scaleX(1.5);
    font-size: 44px;
    letter-spacing: -3px;
    transform-origin: 0 0;
    margin: 0 0 3px 0;
  }
  h4 {
    font-family: "Times New Roman", Times, serif;
    color: ${(props) =>
      props.open ? "rgba(255,255,255,0.8)" : "rgba(0, 0, 0, 0.8)"};
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    transform: scaleX(1.5);
    letter-spacing: -0.6px;
    transform-origin: 0 0;
    transition: color 0.2s;
  }
  @media screen and (max-width: 1000px) {
    overflow: hidden;
    text-align: center;
    width: 100%;
    h1 {
      font-size: 32px;
      letter-spacing: -2px;
      transform-origin: center;
    }
    h4 {
      transform-origin: center;
    }
  }
  @media screen and (max-width: 400px) {
    h1 {
      font-size: 24px;
      letter-spacing: -1px;
    }
    h4 {
      font-size: 11px;
      letter-spacing: -0.4px;
    }
  }
`;

const Color1 = styled.span`
  color: #f779d8;
`;
const Color2 = styled.span`
  color: #789ff9;
`;
const Color3 = styled.span`
  color: #45c5b3;
`;

const HeaderRight = styled.div`
  z-index: 1;
  font-family: "Times New Roman", Times, serif;
  color: #6161ff;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  transform: scaleX(1.5);
  letter-spacing: -0.6px;
  transform-origin: 100% 0;
  max-width: calc(75% - 500px);
  img {
    width: 32px;
    height: 32px;
  }
  @media screen and (max-width: 1000px) {
    max-width: 66%;
    margin-top: 20px;
    text-align: center;
    transform-origin: center;
  }
`;

const ImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const DailyImage = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: transform 0.2s;
  transform: scale(${(props) => (props.expand ? 1.5 : 1)});
  z-index: ${(props) => (props.expand ? 2 : 1)};
  padding: 2px;
  @media screen and (max-width: 600px) {
    width: 60px;
    height: 60px;
  }
`;

const DI2 = React.memo(DailyImage);

const Loading = styled.div`
  width: 100%;
  font-weight: 400;
  font-family: "Arial", Arial, sans-serif;
  transform: scaleX(1.5);
  font-size: 24px;
  letter-spacing: -2px;
  margin: 0 0 40px 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
`;

const Footer = styled.div`
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    max-height: 70px;
    opacity: 0.7;
    margin-right: -15px;
    max-width: 100%;
  }
  div {
    display: flex;
    align-items: center;
  }
  div img,
  div a {
    opacity: 0.95;
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
  span {
    font-family: "Times New Roman", Times, serif;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    transform: scaleX(1.3);
    letter-spacing: -0.6px;
    transform-origin: 0 0;
    display: inline-block;
  }
  a {
    color: #f779d8;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const ImgWrap = React.memo(({ daily, index, setActive, setChangeText }) => {
  let [openTime, setOpenTime] = useState(null);

  let preview = "data:image/jpeg;base64," + daily.preview;

  let onClick = (n, i) => () => {
    window.history.pushState(null, null, "/" + n);
    setActive(i);
    setChangeText(false);
  };
  let onMouseEnter = () => setOpenTime(Date.now());
  let onMouseLeave = () => {
    if (Date.now() - openTime > 400) setOpenTime(null);
    setTimeout(() => setOpenTime(null), 200 - (Date.now() - openTime));
  };
  return (
    <DI2
      key={daily.number}
      src={preview}
      onClick={onClick(daily.number, index)}
      expand={openTime ? true : false}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
});

const ImgListWrap = React.memo(({ dailies, setActive, setChangeText }) => {
  return dailies ? (
    dailies.map((daily, index) => (
      <ImgWrap
        key={index}
        daily={daily}
        index={index}
        setActive={setActive}
        setChangeText={setChangeText}
      />
    ))
  ) : (
    <div />
  );
});

function App() {
  let [dailies, setDailies] = useState(null);
  let [active, setActive] = useState(null);
  let [changeText, setChangeText] = useState(active ? false : true);

  useEffect(() => {
    if (dailies) return;
    async function getDailies() {
      const dailiesGet = await axios.get(
        "https://major-dailies.s3.us-east-005.backblazeb2.com/dailies.json"
      );
      setDailies(dailiesGet.data);
      let path = window.location.pathname.split("/")[1];
      if (path) {
        setChangeText(false);
        setActive(
          dailiesGet.data.findIndex(
            (daily) => String(daily.number) === String(path)
          )
        );
      }
    }
    getDailies();
  });

  return (
    <Container>
      <Header>
        <HeaderLeft open={!changeText}>
          <h1>
            <Color1>major</Color1> <Color2>daily</Color2>{" "}
            <Color3>series</Color3>
          </h1>
          <h4>a piece of art, every day, for 1000 days</h4>
        </HeaderLeft>
        <HeaderRight>
          in November 2017, i was a new artist, and very discouraged about the
          quality of my work. whilst at college, one of my friends took £100
          from me and said i would only get the money back if i completed a 90
          day challenge in order to improve my art. 1000 days later, and i owe
          everything to this series. i’ve started to carve a career and shared
          so many fantastic experiences with new, beautiful friends because of
          what i created every day. i can say with complete confidence that, if
          not for this series, i would not be able to live off of freelance art.
          thank you to every single person who supported a piece of mine in any
          shape or form over the last ~3 years.
          <br />
          <br />i love you all. thank you for everything. ~ noah (major)
        </HeaderRight>
      </Header>
      <ImageList>
        <ImgListWrap
          dailies={dailies}
          setActive={setActive}
          setChangeText={setChangeText}
        />
      </ImageList>
      {dailies === null && <Loading>LOADING...</Loading>}
      {active !== null && dailies && (
        <BigImage
          daily={dailies[active]}
          changeActive={(x) => {
            if (x === null) window.history.pushState(null, null, "/");
            else {
              window.history.pushState(
                null,
                null,
                "/" + x === null ? "" : dailies[x].number
              );
            }
            setActive(x);
          }}
          index={active}
          length={dailies.length}
          setChangeText={setChangeText}
        />
      )}
      <Footer>
        <div>
          <a href="https://twitter.com/majorbean_" target="_blank">
            <img src="/bobosmile.jpg" alt="bobosmile" />
          </a>{" "}
          <span>
            &copy; 2020 <a href="https://twitter.com/majorbean_">noah major</a>
          </span>
        </div>
        <img src="/logos.png" />
      </Footer>
    </Container>
  );
}

export default App;
