import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

document.onmousemove = (e) => {
  document.getElementById("video").style.left =
    (e.clientX / window.innerWidth) * 200 - 200 + "px";
  document.getElementById("video").style.top =
    (e.clientY / window.innerHeight) * 100 - 100 + "px";
};
