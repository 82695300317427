import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";

const BackgroundOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(6px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition: opacity 0.3s;
  opacity: ${(props) => (props.visible ? 1 : 0)};
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(100vw - 100px);
  transform: perspective(1px) scale(${(props) => (props.visible ? 1 : 0)});
  transition: transform 0.3s;
  @media screen and (max-width: 600px) {
    max-width: 100vw;
  }
`;

const ArrowButton = styled.div`
  cursor: pointer;
  svg {
    fill: rgba(255, 255, 255, 0.7);
    transition: fill 0.2s;
  }
  :hover {
    svg {
      fill: rgba(255, 255, 255, 1);
    }
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 50px;
  @media screen and (max-width: 600px) {
    margin: 0 25px;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
`;

const Loading = styled.div`
  display: inline-block;
  position: absolute;
  height: ${(props) => props.dimensions.h}px;
  width: ${(props) => props.dimensions.w}px;
  top: 0;
  left: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Arial", Arial, sans-serif;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.loading ? 1 : 0)};
  transition: opacity 0.15s;
  span {
    transform: scaleX(1.5);
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: calc(100vh - 250px);
  width: auto;
  height: auto;
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
`;

const Description = styled.span`
  font-weight: 400;
  font-family: "Arial", Arial, sans-serif;
  transform: scaleX(1.5);
  font-size: 16px;
  letter-spacing: -0.6px;
  transform-origin: 0 0;
  display: inline-block;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 2px;
  strong {
    font-weight: 400;
    color: white;
    font-size: 18px;
  }
  max-width: ${(props) => {
    if (props.hasSwitch && props.hasLink) return 30;
    if (props.hasSwitch || props.hasLink) return 40;
    return 65;
  }}%;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    transform-origin: center;
    strong {
      font-size: 16px;
    }
  }
`;

const SwitchImage = styled.div`
  display: flex;
  margin-top: 1px;
  height: 26px;
  @media screen and (max-width: 600px) {
    margin: 5px 0;
  }
`;

const SwitchImageButton = styled.div`
  color: ${(props) => (props.active ? "black" : "white")};
  transform: scaleX(1.5);
  transform-origin: 0 0;
  font-family: "Arial", sans-serif;
  padding: 4px;
  margin: 0 8px;
  cursor: pointer;
  border-radius: 4px;
  background: ${(props) => (props.active ? "white" : "transparent")};
`;

const ExternalLink = styled.a`
  font-weight: 400;
  font-family: "Arial", Arial, sans-serif;
  transform: scaleX(1.5);
  font-size: 16px;
  letter-spacing: -0.6px;
  transform-origin: 100% 0;
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
  max-width: 65%;
  margin-top: 2px;
  @media screen and (max-width: 600px) {
    font-size: 16px;
    margin-top: 5px;
    transform-origin: center;
  }
`;

const BC2 = styled.div`
  font-family: "Times New Roman", Times, serif;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.6px;
  @media screen and (max-width: 600px) {
    text-align: center;
    transform-origin: center;
  }
`;

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

const BigImage = function (props) {
  let [imgoption, setImgoption] = useState(0);
  let [loading, setLoading] = useState(false);
  let [dimensions, setDimensions] = useState({ w: 0, h: 0 });
  let [swipe_det, set_swipe_det] = useState({
    sX: 0,
    sY: 0,
    eX: 0,
    eY: 0,
  });
  const swipeTarget = useRef(null);

  const deltaMin = 75;
  // Directions enumeration
  const directions = Object.freeze({
    UP: "up",
    DOWN: "down",
    RIGHT: "right",
    LEFT: "left",
  });
  let direction = null;
  let f1 = function (e) {
    const t = e.touches[0];
    set_swipe_det({ ...swipe_det, sX: t.screenX, sY: t.screenY });
    swipe_det.sX = t.screenX;
    swipe_det.sY = t.screenY;
  };
  let f2 = function (e) {
    // Prevent default will stop user from scrolling, use with care
    // e.preventDefault();
    const t = e.touches[0];
    set_swipe_det({ ...swipe_det, eX: t.screenX, eY: t.screenY });
    swipe_det.eX = t.screenX;
    swipe_det.eY = t.screenY;
  };
  let f3 = (func) =>
    function (e) {
      const deltaX = swipe_det.eX - swipe_det.sX;
      const deltaY = swipe_det.eY - swipe_det.sY;
      // Min swipe distance, you could use absolute value rather
      // than square. It just felt better for personnal use
      if (deltaX ** 2 + deltaY ** 2 < deltaMin ** 2) return;
      if (swipe_det.eX === 0 && swipe_det.eY === 0) return;
      // horizontal
      if (deltaY === 0 || Math.abs(deltaX / deltaY) > 1)
        direction = deltaX > 0 ? directions.RIGHT : directions.LEFT;
      // vertical
      else direction = deltaY > 0 ? directions.UP : directions.DOWN;
      if (direction && typeof func === "function") func(direction);

      direction = null;
    };
  let f4 = (key) => {
    if (key.keyCode === 37) {
      if (props.index > 0) {
        setLoading(true);
        setImgoption(0);
        props.changeActive(props.index - 1);
      }
    }
    if (key.keyCode === 39) {
      if (props.index < props.length - 1) {
        setLoading(true);
        setImgoption(0);
        props.changeActive(props.index + 1);
      }
    }
  };

  const daily = props.daily;

  useEffect(() => {
    if (!swipeTarget.current) return;
    const el = swipeTarget.current;
    el.addEventListener("touchstart", f1, false);
    el.addEventListener("touchmove", f2, false);
    el.addEventListener(
      "touchend",
      f3((b) => {
        set_swipe_det({ sX: 0, sY: 0, eX: 0, eY: 0 });
        if (b === "right") {
          if (props.index > 0) {
            setLoading(true);
            setImgoption(0);
            props.changeActive(props.index - 1);
          }
        }
        if (b === "left") {
          if (props.index < props.length - 1) {
            setLoading(true);
            setImgoption(0);
            props.changeActive(props.index + 1);
          }
        }
      }),
      false
    );
    document.addEventListener("keydown", f4);

    return () => {
      el.removeEventListener("touchstart", f1, false);
      el.removeEventListener("touchmove", f2, false);
      el.removeEventListener("touchend", f3, false);
      document.removeEventListener("keydown", f4);
      setImgoption(0);
    };
    // eslint-disable-next-line
  }, [daily]);

  if (!daily) return <div />;

  return (
    <ImageContainer visible={props.visible} ref={swipeTarget}>
      {props.index > 0 && (
        <ArrowButton
          onClick={() => {
            setLoading(true);
            setImgoption(0);
            props.changeActive(props.index - 1);
          }}
        >
          <svg
            height="50px"
            width="50px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M375,504a8,8,0,0,1-5.67-2.35L132.23,263.83a11.11,11.11,0,0,1,0-15.65L369.33,10.35a8,8,0,0,1,11.33,11.3L147,256,380.66,490.35A8,8,0,0,1,375,504Z"></path>
          </svg>
        </ArrowButton>
      )}
      <CenterContainer>
        <ImgContainer loading={loading} dimensions={dimensions}>
          <Image
            src={
              "https://major-dailies.s3.us-east-005.backblazeb2.com/" +
              props.daily.images[imgoption].file
            }
            onLoad={(e) => {
              setDimensions({
                w: e.target.clientWidth,
                h: e.target.clientHeight,
              });
              setLoading(false);
            }}
          />
          <Loading dimensions={dimensions} loading={loading}>
            <span>LOADING...</span>
          </Loading>
        </ImgContainer>
        <BottomContent>
          <Description
            hasSwitch={props.daily.images.length > 1}
            hasLink={props.daily.url}
          >
            <strong>“{props.daily.images[imgoption].name}”</strong> &mdash;
            daily number {props.daily.number}
            <br />
            <BC2>
              {months[props.daily.date.month - 1]} {props.daily.date.day},{" "}
              {props.daily.date.year}
            </BC2>
          </Description>
          {props.daily.images.length > 1 && (
            <SwitchImage>
              {props.daily.images.map((i, n) => (
                <SwitchImageButton
                  active={imgoption === n}
                  key={n}
                  onClick={() => {
                    setLoading(true);
                    setImgoption(n);
                  }}
                >
                  {n + 1}
                </SwitchImageButton>
              ))}
            </SwitchImage>
          )}
          {props.daily.url && (
            <ExternalLink href={props.daily.url} target="_blank">
              view original{props.daily.audio && " (w/ audio)"}
            </ExternalLink>
          )}
        </BottomContent>
        <BC2></BC2>
      </CenterContainer>
      {props.index < props.length - 1 && (
        <ArrowButton
          onClick={() => {
            setLoading(true);
            setImgoption(0);
            props.changeActive(props.index + 1);
          }}
        >
          <svg
            height="50px"
            width="50px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M137,504a8,8,0,0,1-5.67-13.65L365,256,131.33,21.65a8,8,0,0,1,11.34-11.3l237.1,237.82a11.12,11.12,0,0,1,0,15.66L142.67,501.65A8,8,0,0,1,137,504ZM368.43,259.47h0Z"></path>
          </svg>
        </ArrowButton>
      )}
    </ImageContainer>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => BigImage.handleClickOutside,
};

const BigImage2 = onClickOutside(BigImage, clickOutsideConfig);

const External = (props) => {
  let [closing, setClosing] = useState(false);
  let [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!closing) setVisible(true);
  }, [closing]);

  const close = function () {
    setVisible(false);
    setClosing(true);
    props.setChangeText(true);
    setTimeout(() => props.changeActive(null), 200);
  };

  BigImage.handleClickOutside = close;

  return (
    <BackgroundOverlay visible={visible}>
      <BigImage2 {...props} visible={visible} />
    </BackgroundOverlay>
  );
};

export default External;
